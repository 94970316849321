.voltage-map-table-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
    border: 0.063rem solid #EAECF0;
    border-radius: 0.75rem;
    width: 100%;
    max-width: 1200px; /* Fixed width for the table */
    min-width: 800px; /* Minimum width to prevent shrinking */
}

.voltage-map-table-header {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr; /* Fixed grid layout */
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #475467;
    padding: 0.75rem 1.5rem;
    background: #F9FAFB;
    border-bottom: 0.063rem solid #EAECF0;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.voltage-map-table-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #365A6D;
    padding: 1rem 1.5rem;
    border-top: 0.063rem solid #EAECF0;
    cursor: pointer;
}

.voltage-map-table-body {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr; /* Fixed grid layout */
    padding: 1rem 1.5rem;
    align-items: center; /* Align items vertically */
}

.voltage-map-table-body-with-divider {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr; /* Fixed grid layout */
    padding: 1rem 1.5rem;
    border-bottom: 0.063rem solid #EAECF0; /* Only bottom border */
    align-items: center; /* Align items vertically */
}

.voltage-map-table-remove-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #B42318;
    gap: 0.75rem;
}

.voltage-map-table-remove-button button {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #B42318;
}

.voltage-map-table-remove-button button:hover {
    text-decoration: underline;
}

.voltage-map-table-body-list-container {
    max-height: 27.313rem;
    overflow-y: auto;
}

.voltage-input {
    width: 70%; /* Take up full width of the cell */
    padding: 0.8rem;
    border: 1px solid #EAECF0;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.voltage-map-table-data {
    font-size: 0.875rem; /* Set the desired font size */
}