.add-device-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 32px 48px;
    gap: 24px;
}

.add-device-header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0px 0px;
    gap: 24px;
}

.add-device-header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

.add-device-form-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.add-device-form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 24px;
}

.add-device-form-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    gap: 20px;
}

.add-device-form-header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 16px;
    width: 100%;
}

.add-device-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
}

.add-device-form-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    gap: 20px;
}

.add-device-form-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px;
    gap: 32px;
}

.add-device-form-text-container {
    display: flex;
    flex-direction: column;
    width: 280px;
    padding: 0px;
}

.add-device-form-main-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.add-device-field {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.add-device-form-supporting-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.add-device-input {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}

.add-device-error-message {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #F04438;
}

.add-device-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}