.percentage-battery-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
    border: 0.063rem solid #EAECF0;
    border-radius: 0.75rem;
    width: 100%;
    max-width: 1200px;
    min-width: 800px;
}

.percentage-battery-header {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.125rem;
    color: #475467;
    padding: 0.75rem 1.5rem;
    background: #F9FAFB;
    border-bottom: 0.063rem solid #EAECF0;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.percentage-battery-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.25rem;
    color: #365A6D;
    padding: 1rem 1.5rem;
    border-top: 0.063rem solid #EAECF0;
    cursor: pointer;
}

.percentage-battery-body {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    padding: 1rem 1.5rem;
    align-items: center;
}

.percentage-battery-body-with-divider {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    padding: 1rem 1.5rem;
    border-bottom: 0.063rem solid #EAECF0;
    align-items: center;
}

.percentage-battery-remove-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #B42318;
    gap: 0.75rem;
}

.percentage-battery-remove-button button {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #B42318;
}

.percentage-battery-remove-button button:hover {
    text-decoration: underline;
}

.percentage-battery-body-list-container {
    max-height: 27.313rem;
    overflow-y: auto;
}

.percentage-battery-input {
    width: 70%;
    padding: 0.8rem;
    border: 1px solid #EAECF0;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    box-sizing: border-box;
}

.percentage-battery-data {
    font-size: 0.875rem;
}
